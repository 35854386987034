import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Observable, of } from 'rxjs';
import { catchError, switchMap, exhaustMap, map, mergeMap, tap, filter } from 'rxjs/operators';

import { Message } from '../../model';
import { MessageService, OrgIdService } from '../../services';
import { ActionWithPayload, MessageActions } from '../actions';

import { AngularFireDatabase } from '@angular/fire/compat/database';

@Injectable()
export class MessageEffects {
  monitoring:any;

  constructor(
    private actions$: Actions,
    private actions: MessageActions,
    private service: MessageService,
    private db: AngularFireDatabase,
    private orgIdService: OrgIdService
  ) {
    this.monitoring = {};
  }


  updateLastMessageRead$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{campaignId:string, contactId:string}>>(MessageActions.UPDATE_LAST_READ),
    tap(async action => {
      const v = action.payload;
      await this.db.object(`/organizations/${this.orgIdService.getOrgId()}/conversations/${v.campaignId}/${v.contactId}/unread`).set(false);
    })
  ), { dispatch: false});


  clearRevived$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{campaignId:string, contactId:string}>>(MessageActions.CLEAR_REVIVED),
    tap(async action => {
      const v = action.payload;
      await this.db.object(`/organizations/${this.orgIdService.getOrgId()}/conversations/${v.campaignId}/${v.contactId}/revive`).remove();
    })
  ), { dispatch: false});


  setRevived$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{campaignId:string, contactId:string}>>(MessageActions.SET_REVIVED),
    tap(async action => {
      const v = action.payload;
      await this.db.object(`/organizations/${this.orgIdService.getOrgId()}/conversations/${v.campaignId}/${v.contactId}/revive`).set(true);
    })
  ), { dispatch: false});


  updateLastMessageViewed$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{campaignId:string, contactId:string, phoneNumber:string, method:string, uid:string, lastKey:string}>>(MessageActions.UPDATE_LAST_VIEWED),
    tap(action => {
      const v = action.payload;
      if (v.lastKey && v.uid) {
        this.db.object(`/organizations/${this.orgIdService.getOrgId()}/messages/${v.campaignId}/${v.contactId}/${v.phoneNumber}/${v.method}/lastViewed/${v.uid}`).set(v.lastKey);
      }
    })
  ), { dispatch: false});


  loadAllForConversation$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{campaignId:string, contactId:string}>>(MessageActions.LOAD_ALL_FOR_CONVERSATION),
    mergeMap(action => {
      console.log(`*!*!*!*!*! Starting ALL msgs sub for ${action.payload.campaignId}/${action.payload.contactId}`);
      const key = `/organizations/${this.orgIdService.getOrgId()}/messages/${action.payload.campaignId}/${action.payload.contactId}`;
      if (!this.monitoring[key]) {
        this.monitoring[key] = this.db.object(key).snapshotChanges();
      }
      return this.monitoring[key];
    }, (origValue, newValue) => ({ campaignId: origValue.payload.campaignId, contactId: origValue.payload.contactId, messages: (<any>newValue).payload.val() })),
    catchError( (err, caught) => {
      console.warn('Error in MessageEffects.loadAllForConversation$', err, caught);
      this.monitoring = {};
      return of(null);
    }),
    filter(v => !!v),
    map(action => {
      return {
        type: MessageActions.LOAD_ALL_FOR_CONVERSATION_SUCCESS,
        payload: action
      };
    })
  ));


  loadForConversation$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{campaignId:string, contactId:string, phoneNumber:string}>>(MessageActions.LOAD_FOR_CONVERSATION),
    mergeMap(action => {
      return this.db.object(`/organizations/${this.orgIdService.getOrgId()}/messages/${action.payload.campaignId}/${action.payload.contactId}/sms/${action.payload.phoneNumber}`).snapshotChanges();
    }),
    catchError( (err, caught) => {
      console.warn('Error in MessageEffects.loadForConversation$', err, caught);
      return of(null);
    }),
    filter(v => !!v),
    map(action => {
      const obj = action.payload.val();
      return {
        type: MessageActions.LOAD_FOR_CONVERSATION_SUCCESS,
        payload: { conversationId: action.key, value: obj }
      };
    })
  ));


  add$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{campaignId:string, contactId:string, phoneNumber:string, message: Message}>>(MessageActions.ADD),
    tap((action) => {
      this.service.addMessage(action.payload.campaignId, action.payload.contactId, action.payload.phoneNumber, action.payload.message);
    }),
    filter(() => false)
  ));
}
